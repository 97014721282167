/* eslint-disable react/jsx-pascal-case */
import { React, useState } from 'react';
import { Loading } from './LoadingComponent';
import { Button } from 'react-bootstrap';
import {  Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PagesHeader from './PagesHeaderComponent';
import ModalForm from './ModalFormComponent';

// function ModalForm ({ job, applicant, handleClose, showModal }) {
//     const [applicantData, setApplicantData] = useState({ firstname: '', lastname: '', email:'', telnum: '', resume: '' });
//     const dispatch = useDispatch();

//     useEffect(() => {
//         if (applicant) setApplicantData(applicant);
//     }, [applicant]);

//     const clear = () => {
//         setApplicantData({ firstname: '', lastname: '', email:'', telnum: '', resume: '' });
//     }

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         dispatch(createApplicant(applicantData));
//         clear();
//     }

//     return (
//         <Modal show={showModal} onHide={handleClose}>
//             <ModalHeader closeButton>Apply for {job.jobPosition}
//             </ModalHeader>
//             <ModalBody>
//                 <LocalForm action="/uploadresume" onSubmit={handleSubmit}>
//                     <Row className="form-group">
//                         <Col>
//                             <FormLabel htmlFor="firstname">First Name</FormLabel>
//                             <Control.text model=".firstname" id="firstname" 
//                             value={applicantData.firstname} className="form-control" onChange={(e) => setApplicantData({ ...applicantData, firstname: e.target.value })}/>
//                         </Col>
//                     </Row>
//                     <Row className="form-group">
//                         <Col>
//                             <FormLabel htmlFor="lastname">Last Name</FormLabel>
//                             <Control.text model=".lastname" id="lastname" className="form-control"
//                             value={applicantData.lastname} onChange={(e) => setApplicantData({ 
//                             ...applicantData, lastname: e.target.value })}/>
//                         </Col>
//                     </Row>
//                     <Row className="form-group">
//                         <Col>
//                             <FormLabel htmlFor="email">Email</FormLabel>
//                             <Control.text model=".email" id="email" className="form-control" 
//                             value={applicantData.email} onChange={(e) => setApplicantData({
//                             ...applicantData, email: e.target.value })}/>
//                         </Col>
//                     </Row>
//                     <Row className="form-group">
//                         <Col>
//                             <FormLabel htmlFor="telnum">Tel. </FormLabel>
//                             <Control.text model=".telnum" id="telnum" className="form-control" value={applicantData.telnum}
//                             onChange={(e) => setApplicantData({...applicantData, 
//                             telnum: e.target.value })}/>
//                         </Col>
//                     </Row>
//                     <Row className="form-group">
//                         <Col>
//                             <FileBase type="file" multiple={false} onDone={({ base64 }) =>setApplicantData({...applicantData, resume: base64 }) }/>
//                         </Col>
//                     </Row>
//                     <Row className="mt-3">
//                         <Col md={2}>
//                             <Button type="submit" className="bg-primary">
//                                 Submit
//                             </Button>
//                         </Col>
//                         <Col md={3}>
//                             <Button type="submit" onClick={handleClose}> 
//                                 Cancel   
//                             </Button>
//                         </Col>
//                     </Row>
//                 </LocalForm>
//             </ModalBody>
//         </Modal>
//     );
// } 

function JobList({ job }) {
    const [showModal, setShow] = useState(false);
    // const [state, setState] = useMergeState({
    //     showModal: false,
    //     postApplicant: 
    // });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <Card style={{ height : '100%'}}>
            <CardContent className="" style={{ padding : 30}}>
                <Typography variant="h4" className="mb-3" style={{ color: "black" }}>{job.jobPosition}</Typography>
                <Typography variant="body1" className="mb-3">{job.jobDescription}</Typography>
                {/* <Typography variant="body" className="mb-3">{job.jobRequirements}</Typography> */}
            </CardContent>
            <Button disabled className="ml-4 mb-3" variant='secondary' onClick={handleShow}>Apply Now</Button>
            <span style={{ verticalAlign : 'super', marginLeft : 20}}>No longer accepting applications</span>
        </Card> 
        <ModalForm job={job} handleClose={handleClose} showModal={showModal}/>
        </>
    );
}

function Careers ({ currentId, setCurrentId }) {
    const jobs = useSelector((state) => state.jobs);

    return(
        !jobs.length ? <Loading /> : (
            <>
            <PagesHeader/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="careers-img">
                            <img src="../assets/images/joinus.jpg" alt="Join us"
                                style={{opacity: 0.7, width: "100%", height: '250px', objectFit : 'cover', objectPosition: 'cover', filter: 'brightness(0.7)'}}/>
                            <div className="centered"><h1 style={{fontSize:"48px", color: 'white'}}>Join us</h1></div>
                        </div>
                    </div>
                </div>
                <div className="container">
                <div className="row">
                    <Grid container spacing={3} style={{padding : 40}}>
                    {jobs.map((job) => 
                        <Grid key={job._id} item xs={12} md={6} className="">
                            <JobList job={job} currentId={currentId} setCurrentId={setCurrentId} />
                        </Grid>
                    )}
                    </Grid>
                </div>
                </div>
            </div>
            </>
        )
    );
}

export default Careers;
